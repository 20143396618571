import { useRef, useEffect, useState } from 'react';
// import { HeroUpLine, HeroFreshLine } from '../../assets/svgs';
import { Button } from '../../ui';
import { Container } from './styles';
import { HeroSectionSlideImgOne, HeroSectionSlideImgThree, HeroSectionSlideImgTwo } from '../../assets/images';
// import { TypeAnimation } from 'react-type-animation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { PopupModal } from 'react-calendly';

const HeroSection = () => {
	const heroRef = useRef();

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				document.querySelector('header').classList.toggle('bright', !entry.isIntersecting);
			});
		});

		observer.observe(heroRef.current);

		return () => observer.disconnect();
	}, []);

	return (
		<Container ref={heroRef}>
			<div>
				{/* <h1>
					Empowering Start-
					<span className="ups">
						Ups
						<HeroUpLine />
					</span>{' '}
					with{' '}
					<span className="fresh">
						Fresh
						<HeroFreshLine />
					</span>{' '}
					Talent.
				</h1> */}
				{/* <h1>
					Connecting small{' '}
					<span className="ups">
						businesses
						<HeroFreshLine />
					</span>{' '}
					with{' '}
					<span className="seasoned">
						skilled
						<HeroUpLine />
					</span>{' '}
					interns
				</h1> */}
				<h1>
					Connecting small businesses <br /> with skilled interns
				</h1>
				{/* <h1>
					Empowering Start-
					<span className="ups">
						Ups
						<HeroFreshLine />
					</span>{' '}
					<TypeAnimation
						className="sme"
						speed={{ type: 'keyStrokeDelayInMs', value: 100 }}
						sequence={[2000, '& SMEs', 1000, () => document.querySelector('.sme').classList.add('done')]}
						wrapper="span"
					/>{' '}
					<br />
					With{' '}
					<span className="seasoned">
						Seasoned
						<HeroUpLine />
					</span>{' '}
					<TypeAnimation
						className="talent"
						speed={{ type: 'keyStrokeDelayInMs', value: 100 }}
						sequence={[
							4500,
							() => document.querySelector('.talent').classList.add('start'),
							2000,
							'Talents.',
							4000,
							() => document.querySelector('.talent').classList.add('done'),
						]}
						wrapper="span"
					/>
				</h1> */}
				<p>Fueling Start-Ups with Eager Interns and Graduates, to drive growth and empower Innovation.</p>

				<div>
					<a href="https://app.descinder.com/signup">
						<Button>GET STARTED</Button>
					</a>
					<Button
						onclick="Calendly.initPopupWidget({url: 'https://calendly.com/account-descinder/introductory-call'});return false;"
						variant="secondary"
						onClick={() => setIsOpen(true)}
					>
						INTRODUCTORY CALL
					</Button>

					<PopupModal
						url="https://calendly.com/account-descinder/introductory-call"
						//   pageSettings={this.props.pageSettings}
						//   utm={this.props.utm}
						//   prefill={this.props.prefill}
						onModalClose={() => setIsOpen(false)}
						open={isOpen}
						/*
						 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
						 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
						 */
						rootElement={document.getElementById('root')}
					/>
				</div>
			</div>

			<Swiper
				speed={700}
				modules={[Autoplay]}
				spaceBetween={57}
				slidesPerView={'auto'}
				autoplay={{ delay: 2500 }}
				loop={true}
				className="swiper-con"
				centeredSlides={true}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 0,
					modifier: 1,
					slideShadows: false,
				}}
			>
				<SwiperSlide>{({ isActive }) => <img src={HeroSectionSlideImgOne} alt="" className={isActive ? 'active' : ''} />}</SwiperSlide>
				<SwiperSlide>{({ isActive }) => <img src={HeroSectionSlideImgTwo} alt="" className={isActive ? 'active' : ''} />}</SwiperSlide>
				<SwiperSlide>{({ isActive }) => <img src={HeroSectionSlideImgThree} alt="" className={isActive ? 'active' : ''} />}</SwiperSlide>
			</Swiper>

			{/* <div className="swiper-con">
				<img src={HeroSectionSlideImgOne} alt="" />
				<img src={HeroSectionSlideImgTwo} alt="" className="active" />
				<img src={HeroSectionSlideImgThree} alt="" />
			</div> */}
		</Container>
	);
};

export default HeroSection;
